import nextCookie from 'next-cookies'
import jsCookie from 'js-cookie'
import moment from 'moment-timezone'
import Swal from 'sweetalert2'
import jwt from 'jsonwebtoken'

export const tokenKey = 'minions-back-ofice-token'
export const userMeData = 'userMe'
export const userRole = 'userRole'
export const OperatorIdData = 'OpeartorId'
export const menuPermission = 'menuPermission'

export function checkTokenExpire(token: string) {
  const tokentConv = parseJwt(token)
  const currentTime = moment().format('X')
  const expireTime = tokentConv.exp

  // 1597996001
  if (currentTime < expireTime) {
    return true
  }
  return false
}

export function setClearToken(): void {
  jsCookie.remove(tokenKey)
  jsCookie.remove(userMeData)
  jsCookie.remove(userRole)
  jsCookie.remove(menuPermission)
  localStorage.removeItem('permission_member')
  sessionStorage.removeItem('SelectedKeys')
  sessionStorage.removeItem('OpenKeys')
}

export function setToken(token: string): void {
  jsCookie.set(tokenKey, token, { expires: 7 })
}

export function setUserMe(userMe: string): void {
  localStorage.removeItem('statusLogout')
  jsCookie.set(userMeData, userMe, { expires: 7 })
}

export function setUserRole(role: string): void {
  jsCookie.set(userRole, role, { expires: 7 })
}

export function setOperatorId(OperatorId: number): void {
  jsCookie.set(OperatorIdData, OperatorId.toString(), { expires: 7 })
}

export function setMenuPermission(role: string): void {
  jsCookie.set(menuPermission, role, { expires: 7 })
}

export function getToken(ctx = null): string | undefined {
  if (process.browser || !ctx) {
    return jsCookie.get(tokenKey)
  } else {
    return nextCookie(ctx)[tokenKey]
  }
}

export function getUserMe(ctx = null): string | undefined {
  if (process.browser || !ctx) {
    return jsCookie.get(userMeData)
  } else {
    return nextCookie(ctx)[userMeData]
  }
}
export function getUserRole() {
  return jsCookie.get(userRole)
}
export function getMenuPermission() {
  return jsCookie.get(menuPermission)
}
export function menuStatus(pathname) {
  return jwt.verify(getMenuPermission(), 'permissionMenu', function (err: any, decoded: any) {
    let status = false
    const pathname_with_parametor = pathname.split('/')
    pathname_with_parametor.pop()
    const find_url_parametor = decoded.filter(item => item.toLowerCase().endsWith('%s'))
    const adapt_url_with_parametor = find_url_parametor.map(a => {
      const urls = a.split('/')
      urls.pop()
      return urls.join('/')
    })

    // Detect Parametor Case
    if (inArray(pathname_with_parametor.join('/'), adapt_url_with_parametor)) status = true
    else status = inArray(pathname, decoded)

    return (
      status ||
      inArray(pathname, [
        '/agent',
        '/agent/login',
        '/admin',
        '/admin/login',
        '/portal',
        '/portal/login',
      ])
    )
  })
}

export function getOperatorId(ctx = null): string | undefined {
  if (process.browser || !ctx) {
    return jsCookie.get(OperatorIdData)
  } else {
    return nextCookie(ctx)[OperatorIdData]
  }
}

export function removeUserMe(): void {
  return jsCookie.remove(userMeData)
}

export const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export const unixTime = timestamp => {
  const ts = new Date()
  return ts.toDateString()
}

export const checkToken = () => {
  const statusLogout = localStorage.getItem('statusLogout')

  const dataToken = getToken()
  let chkPeriod = false

  if (dataToken) {
    chkPeriod = checkTokenExpire(dataToken)
  }

  if (!chkPeriod && !statusLogout) {
    Swal.fire({
      title: 'เซสชั่นหมดอายุ!',
      text: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
      icon: 'warning',
      confirmButtonText: 'ตกลง',
    }).then(() => {
      window.location.reload()
    })
    return false
  }

  return true
}

export const refreshToken = () => {
  const dataToken = getToken()
  const payload: any = jwt.verify(dataToken, process.env.JWT_SECRET)
  delete payload.exp
  delete payload.iat
  const newToken = jwt.sign(payload, process.env.JWT_SECRET, {
    expiresIn: Number(process.env.JWT_EXPIRE),
  })
  setToken(newToken)

  return newToken
}

export const getUser = token => {
  const payload = jwt.verify(token, process.env.JWT_SECRET)
  return payload
}

export const logout = () => {
  setClearToken()
  localStorage.setItem('statusLogout', 'true')
}

export function inArray(needle, haystack) {
  for (const i in haystack) {
    if (haystack[i] == needle) return true
  }
  return false
}

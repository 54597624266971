import React, { useState, createContext } from 'react'

export const StoreContext = createContext({})

export const StoreContextProvider = ({ children }) => {
  const [navBar, setnavBar] = useState({
    background: 'rgb(11, 44, 49)',
    color: '#f4f4f4',
    logo: undefined,
  })

  const [footer, setFooter] = useState({
    background: 'rgb(11, 44, 49)',
    color: '#f4f4f4',
    logo: undefined,
  })
  
  const store = {
    navBar: [navBar, setnavBar],
    footer: [footer, setFooter],
  }

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

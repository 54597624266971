import ApolloClient, { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-boost'
import Sentry from '../src/utils/sentry'
import introspectionQueryResultData from '../src/__schema/fragmentTypes.json'
import { getToken } from '../src/lib/authToken'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

export const getApolloClient = (ctx, initialState) => {
  const cache = new InMemoryCache({ fragmentMatcher }).restore(initialState || {})
  return new ApolloClient({
    cache,
    uri: operation => {
      switch (operation.getContext().clientName) {
        case 'betkub':
          return process.env.GRAPHQL_URL_BETKUB
        default:
          return process.env.GRAPHQL_URL
      }
    },
    request: operation => {
      if (operation.getContext().clientName === 'betkub') {
        operation.setContext({
          headers: {
            clientId: process.env.CLIENT_ID,
            clientSecret: process.env.CLIENT_SECRET,
          },
        })
      } else {
        const token = getToken(ctx)
        operation.setContext({
          headers: {
            ...(token ? { authorization: `Bearer ${token}` } : {}),
          },
        })
      }
    },
    onError: error => {
      // Sentry.captureException(error)
      // console.log('ApolloClientError', error)
    },
  })
}